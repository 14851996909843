
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    text: {
      type: String,
      default: '',
      required: true
    },
    layout: {
      type: String,
      default: '',
      required: true
    },
    url: {
      type: String,
      default: '',
      required: true
    }
  }
});
