
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Gnav',
  data(){
    return{
      open: false
    }
  },
  updated() {
    if(this.$route.path.match('works')){
      document.querySelector('.gnav-item--works')?.classList.add('is--current');
    }
    else {
      document.querySelector('.gnav-item--works')?.classList.remove('is--current');
    }
  }
});
