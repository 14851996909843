import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import VueMeta from "vue-3-meta";
import './assets/scss/main.scss'


const app = createApp(App)
app.use(router)
// app.use(VueMeta)
app.mount('#app')
