<template>
  <div class="bg">
    <div class="bg__gradient"></div>
    <div class="bg__circle-gradient"></div>
    <!-- <div class="bg__txt-wrapper">
      <div class="bg__txt bg__txt--1-1 txt-animation-r"><span>HEBITSUKAI</span></div>
      <div class="bg__txt bg__txt--1-2 txt-animation-r"><span>HEBITSUKAI</span></div>
      <div class="bg__txt bg__txt--2-1 txt-animation-l"><span>ILLUSTRATION</span></div>
      <div class="bg__txt bg__txt--2-2 txt-animation-l"><span>ILLUSTRATION</span></div>
    </div> -->
    
  </div>
</template>

<script lang="ts">
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: $black;
  @include for-sp(){
    width: 100%;
    height: 100%;
  }
  &::after {
    // content: '';
    // display: block;
    // width: 100vw;
    // height: 100vh;
    // background: url(../assets/img/common/noise.svg) repeat-x;
    // filter: brightness(120%);
    // opacity: 0.3;
    // z-index: -5;
  }
  &__gradient {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: calc(440/1920 * 100vw);
    background: linear-gradient(180deg, rgba(28,28,31,1), rgba(255,255,255,0)), url(../assets/img/common/noise.svg);
    z-index: -1;
    @include for-sp(){
      height: calc(300/750 * 100vw);
      opacity: 0.4;
    }
  }
  &__circle-gradient {
    position: absolute;
    top: calc(-220/1920 * 100vw);
    left: calc(750/1920 * 100vw);
    width: calc(680/1920 * 100vw);
    height: calc(680/1920 * 100vw);
    border-radius: 50%;
    background: radial-gradient(circle, rgba(0,0,0,0), rgba(28,28,31,1) 70%), url(../assets/img/common/noise.svg);
    z-index: -1;
    opacity: 0.7;
    @include for-sp(){
      left: calc(300/750 * 100vw);
      width: calc(400/750 * 100vw);
      height: calc(400/750 * 100vw);
      opacity: 0.6;
    }
  }
  &__txt {
    position: absolute;
    @include font-en-ttl;
    line-height: 1;
    font-size: calc(200/1920 * 100vw);
    // text-shadow: rgba(255, 255, 255, 0.5) 1px 1px 0;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.3);
    color: transparent;
    @include for-sp(){
      font-size: calc(100/750 * 100vw);
      -webkit-text-stroke-color: rgba(255, 255, 255, 0.1);
    }
   &--1-1 {
     top: calc(150/1920 * 100vw);
     @include for-sp(){
       top: calc(200/750 * 100vw);
     }
   }
   &--2-1 {
     bottom: calc(150/1920 * 100vw);
     @include for-sp(){
       bottom: calc(200/750 * 100vw);
     }
   }
    &--1-2 {
     top: calc(150/1920 * 100vw);
     left: 100vw;
     @include for-sp(){
       top: calc(200/750 * 100vw);
     }
   }
   &--2-2 {
     bottom: calc(150/1920 * 100vw);
     left: 100vw;
     @include for-sp(){
       bottom: calc(200/750 * 100vw);
     }
   }
   span {
     margin: 0 calc(100/1920 * 100vw);
   }
  }
  &__txt-wrapper {
    width: 100vw;
    height: 100vh;
  }
}


.txt-animation-r {
  // animation: move-r 30s linear 0s infinite normal;
}
.txt-animation-l {
  // animation: move-l 30s linear 0s infinite normal;
}
@keyframes move-r {
  0%{
    transform: translate(-100vw, 0);
  }
  100%{
    transform: translate(0, 0);
  }
}
@keyframes move-l {
  0%{
    transform: translate(0, 0);
  }
  100%{
    transform: translate(-100vw, 0);
  }
}

</style>
