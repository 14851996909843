
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MouseStalker'
});

var mouseCursor:any
var mouseStalker:any

var stalker = {
  x: 0,
  y: 0
}
var mouse = {
  x: 0,
  y: 0
}

//読み込み後に初期化をするためのハンドラ
document.addEventListener('DOMContentLoaded', setup)

//マウスの動きを監視
document.addEventListener('mousemove', mousemove)

//初期化処理
function setup() {
  //マウスストーカー要素を取得
  mouseCursor = document.querySelector('.mouse-cursor');
  mouseStalker = document.querySelector('.mouse-stalker');
  // 更新処理を開始
  update()
}

//マウスが動くたびにマウスの位置を保持しておく
function mousemove(e:any) {
  mouse.x = e.clientX
  mouse.y = e.clientY
}

//更新処理
function update() {
  //マウスストーカー要素の位置を更新
  stalker.x += (mouse.x - stalker.x) * 0.3
  stalker.y += (mouse.y - stalker.y) * 0.3
  //マウスストーカーの位置を小数点第一位まで四捨五入
  var x = Math.round(stalker.x * 10) / 10
  var y = Math.round(stalker.y * 10) / 10
  //マウスストーカー要素のスタイルを更新
  mouseStalker.style.transform = `translate3d(` + x + 'px,' + y + 'px, 0)'
  mouseCursor.style.transform = `translate3d(` + mouse.x + 'px,' + mouse.y + 'px, 0)' 

  //aタグにホバーしたときに、ストーカーにクラスを追加
  const linkElem = document.querySelectorAll('a');
  for (let i = 0; i < linkElem.length; i++) {
    //マウスホバー時
    linkElem[i].addEventListener('mouseover', function () {
        //ストーカーにクラスを追加
        mouseStalker.classList.add('is--hover');
    });
    //マウスホバー解除時
    linkElem[i].addEventListener('mouseout', function () {
        mouseStalker.classList.remove('is--hover');
    });
  }
  // ループ
  requestAnimationFrame(update)
}

