
import { defineComponent } from 'vue';
import Btn from '@/components/Btn.vue';
import json2022 from '@/assets/data/works2022.json';
import json2021 from '@/assets/data/works2021.json';
import json2020 from '@/assets/data/works2020.json';
import json2019 from '@/assets/data/works2019.json';
import json2018 from '@/assets/data/works2018.json';
import json2017 from '@/assets/data/works2017.json';
import json2016 from '@/assets/data/works2016.json';
import json2015 from '@/assets/data/works2015.json';
import json2014 from '@/assets/data/works2014.json';
import json2013 from '@/assets/data/works2013.json';
import json2012 from '@/assets/data/works2012.json';
import json2011 from '@/assets/data/works2011.json';
import json2010 from '@/assets/data/works2010.json';
import * as THREE from 'three';

class Work {
  id = '';
  year = '';
  date = '';
  title = '';
  client = '';
  detail = '';
  pickup = false;
}

export default defineComponent({
  name: 'Home',
  components: {
    Btn
  },
  data() {
    return {
      pickups: [] as Work[],
      isLoaded: false,
      loadedCount: 0
    }
  },
  computed: {
    works2022(){ return json2022.filter(function(w:any){ return w.display === true }); },
    works2021(){ return json2021.filter(function(w:any){ return w.display === true }); },
    works2020(){ return json2020.filter(function(w:any){ return w.display === true }); },
    works2019(){ return json2019.filter(function(w:any){ return w.display === true }); },
    works2018(){ return json2018.filter(function(w:any){ return w.display === true }); },
    works2017(){ return json2017.filter(function(w:any){ return w.display === true }); },
    works2016(){ return json2016.filter(function(w:any){ return w.display === true }); },
    works2015(){ return json2015.filter(function(w:any){ return w.display === true }); },
    works2014(){ return json2014.filter(function(w:any){ return w.display === true }); },
    works2013(){ return json2013.filter(function(w:any){ return w.display === true }); },
    works2012(){ return json2012.filter(function(w:any){ return w.display === true }); },
    works2011(){ return json2011.filter(function(w:any){ return w.display === true }); },
    works2010(){ return json2010.filter(function(w:any){ return w.display === true }); },
  },
  mounted() {
    const allWorks = this.works2022.concat(this.works2021).concat(this.works2020).concat(this.works2019).concat(this.works2018).concat(this.works2017).concat(this.works2016).concat(this.works2015).concat(this.works2014).concat(this.works2013).concat(this.works2012).concat(this.works2011).concat(this.works2010);
    let _pickups = [] as Work[];
    for(let i=0; i<allWorks.length; i++){
      if(allWorks[i].pickup)
        _pickups.push(allWorks[i]);
    }
    //_pickupsの中からランダムに10個抽出（アクセス毎にサムネイル変更）
    _pickups.sort( ()=> Math.random() - 0.5 );
    this.pickups = _pickups.slice(0, 10); ///10*2
    this.pickups = this.pickups.concat(this.pickups.slice());
  },
  methods: {
    loaded(){
      this.loadedCount++
      const progress = (this.loadedCount/20)*100
      document.querySelector<HTMLElement>('.home-loading .num')!.innerHTML = String(Math.floor(progress));
      document.querySelector<HTMLElement>('.home-loading span')!.style.width = progress+'%'
      if(this.loadedCount == 20){ //20
        setTimeout(() => {
          this.isLoaded = true;
        }, 500)
        setTimeout(() => {
          document.querySelector('.home')?.classList.add('is--pointer-event-on');
      }, 2500);
      }
    },
    // waveEffect(){
    //   let geometry = new THREE.PlaneGeometry(0.4, 0.6, 16, 16);
    //   let material = new THREE.ShaderMaterial({
    //     vertexShader,
    //     fragmentShader,
    //     uniforms: {
    //       uTime: { value: 0.0 }
    //     },
    //     wireframe: true,
    //   });
    //   let mesh = new THREE.Mesh(this.geometry, this.material);
    //   let scene.add(this.mesh);
    // }
  },
  beforeRouteLeave (to, from, next) {
    document.querySelector('body')?.classList.remove('home-page')
    next();
  },
  beforeRouteEnter (to, from, next) {
    document.querySelector('body')?.classList.add('home-page')
    next();
  }
});
