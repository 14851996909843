import { resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Gnav = _resolveComponent("Gnav")!
  const _component_Bg = _resolveComponent("Bg")!
  const _component_MouseStalker = _resolveComponent("MouseStalker")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Gnav),
    _createVNode(_component_Bg),
    _createVNode(_component_MouseStalker),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ], 64))
}