
import { defineComponent } from 'vue';
import Gnav from '@/components/Gnav.vue';
import Bg from '@/components/Bg.vue';
import MouseStalker from '@/components/MouseStalker.vue';

export default defineComponent({
  name: 'App',
  components: {
    Gnav, Bg, MouseStalker
  },
  mounted(){
    const touch_event = window.ontouchstart;
    const touch_points = navigator.maxTouchPoints;
    if( touch_event !== undefined && 0 < touch_points ) {
      // console.log('is touch device (ex: SP/TB)');
    }
    else {
      // console.log('is not touch device (ex: PC)')
      document.querySelector('html')?.classList.add('no-touchevents')
    }
  }
  
});
